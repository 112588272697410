// mui
import { Box, Grid, Typography} from "@mui/material";
// photos
import googlePlay from "../../assets/home/googlePlay.png";
import appStore from "../../assets/home/appStore.png";
import landing from "../../assets/home/landing.png";

import { PLAYSTORE } from "../../data/API";

const Landing = () => {
  return (
    <Grid
      container
      spacing={2}
      sx={{ position: "relative", mt: { xs: "30px", md: "217px" }}}
    >
      <Grid item xs={12}>
        <Box
          sx={{
            bgcolor: "#D3C0B3",
            borderRadius: "40px",
            paddingTop: "108px",
            paddingBottom: "94px",
            paddingLeft: "48px",
          }}
        >
          <Typography variant="h1" component="h1">
            Download{" "}
            <Typography
              component="span"
              sx={{
                color: "#212121",
                fontSize: { xs: "12px", sm: "30px" },
                fontWeight: "700",
              }}
            >
              <sub>Our App</sub>
            </Typography>
          </Typography>
          <Typography
            variant="lead"
            component="p"
            sx={{
              paddingRight: { xs: "53px", sm: "25px" },
              maxWidth: { xs: "auto", md: "540px" },
            }}
          >
            Download now and enjoy shopping in our online store {""}
          </Typography>
          <Box sx={{ display: "flex", gap:{xs:"10px", sm:"15px"}, flexDirection:{xs:"column", sm:"row"} }}>
            <a href={PLAYSTORE} target="_blank" rel="noreferrer">
              <img src={googlePlay} alt="google play" />
            </a>
            <a href={PLAYSTORE} rel="noreferrer" sx={{ marginLeft: { xs: "0 !important", sm: "16px" } }}>
              <img src={appStore} alt="app Store" />
            </a>
          </Box>
        </Box>
      </Grid>
      <Box
        sx={{
          position: "absolute",
          right: "-100px",
          top: "-150px",
          display: { xs: "none", sm: "none", lg: "flex" },
        }}
      >
        <img src={landing} alt="landing" width="100%"/>
      </Box>
    </Grid>
  );
};

export default Landing;
