import Slider from "react-slick";
import ProductItem from "../common/productItem"; // Ensure this import is correct

const arrowStyels = {
  borderRadius: "50%",
  zIndex: "9999999",
  fontSize: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "#6E2E02",
};

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`best-seller-slick ${className}`}
      style={{ ...style, display: "block", ...arrowStyels }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`best-seller-slick ${className}`}
      style={{
        ...style,
        display: "block",

        ...arrowStyels,
      }}
      onClick={onClick}
    />
  );
}

export function MultipleItems({ items }) {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="slider-container bestseller-slider">
      <Slider {...settings}>
        {items?.map((item) => (
          // Add a unique key prop for each item in the list
          <ProductItem
            description={item.description}
            id={item.id}
            image={item.image}
            unit_price={item.unit_price}
            wholesale_description={item.wholesale_description}
            key={item.id}
            product_name={item.product_name}
          />
        ))}
      </Slider>
    </div>
  );
}
