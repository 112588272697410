import React, { useState } from "react";
import { ProfileCard } from "../../shared/style";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { GrayButton } from "../../shared/style";
import {
  deleteShipping,
  getShipping,
  postShipping,
  setErrorMsg,
} from "../../store/shipping/shippingSlice";
import trash from "../../assets/cart/trash.png";
import ShippingForm from "../shipping/shippingForm";
import { toast } from "react-toastify";
import DialogShipping from "../shipping/dialogShipping";
import AddressSave from "./addressSave";

const AddressSection = () => {
  const { allShipping } = useSelector((state) => state.shipping);
  const {
    country: countryValue,
    name,
    company_name,
    house_number,
    city,
    postal_code,
    governoate,
    phone,
    phone_number,
    address,
  } = useSelector((state) => state?.shipping?.shipping);
  const dispatch = useDispatch();

  //  open Dialog if shipping is bigger than 10
  const [open, setOpen] = React.useState(false);

  //  handle open Dialog if shipping is bigger than 10
  const handleClickOpen = () => {
    setOpen(true);
  };

  //  handle close Dialog if shipping is bigger than 10
  const handleClose = () => {
    setOpen(false);
  };

  const dispatchReturn = (name, value) => {
    return dispatch(setErrorMsg({ name, value }));
  };
  // validition Form
  const valditionForm = () => {
    let test = true;
    if (phone_number == null) {
      dispatch(
        setErrorMsg({ name: "phone_number", value: "this is field is requird" })
      );
      test = false;
    } else {
      dispatch(setErrorMsg({ name: "phone_number", value: "" }));
    }

    if (address == "") {
      dispatch(
        setErrorMsg({ name: "address", value: "this is field is requird" })
      );
      test = false;
    } else {
      dispatch(setErrorMsg({ name: "address", value: "" }));
    }

    if (postal_code == "") {
      dispatch(
        setErrorMsg({ name: "postal_code", value: "this is field is requird" })
      );
      test = false;
    } else {
      dispatch(setErrorMsg({ name: "postal_code", value: "" }));
    }

    if (city == "") {
      dispatch(
        setErrorMsg({ name: "city", value: "this is field is requird" })
      );
      test = false;
    } else {
      dispatch(setErrorMsg({ name: "city", value: "" }));
    }
    return test;
  };

  const shippingProfile = useState({name: "", country: "", company_name: "", house_number:"", postal_code, governoate, })
  const handlePostShipping = (e) => {
    e.preventDefault();

    if (!valditionForm()) {
      return false;
    }
    if (allShipping.length >= 10) {
      handleClickOpen();
      return false;
    }

    dispatch(
      postShipping({
        name,
        country: countryValue,
        company_name,
        house_number,
        city,
        postal_code,
        governoate,
        phone_number,
        address,
      })
    ).then((result) => {
      if (result.type === "postShipping/fulfilled") {
        dispatch(getShipping());
      }
    });
  };

  return (
    <>
      {allShipping.length > 0 && (
        <ProfileCard>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              gap: "15px",
            }}
          >
            <Typography
              variant="h4"
              component="h3"
              sx={{
                fontSize: "20px",
                fontWeight: "600",
                marginLeft: "8px",
                mb: "10px",
              }}
            >
              Saved Address
            </Typography>

            {allShipping?.map((item) => {
              return (
                <Box key={item.id} sx={{ position: "relative" }} onClick={() => console.log(item.id)}>
                  <Typography
                    sx={{
                      border: "1px solid #ddd",
                      padding: "17px",
                      borderRadius: "5px",
                      color: "rgba(144, 144, 144, 1)",
                      borderColor: "rgba(217, 217, 217, 1)",
                      cursor: "pointer",
                    }}
                  >
                    {item.address}, {item.country}, {item.postal_code}
                  </Typography>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "35%",
                      right: "10px",
                      transform: "translate(-50%, 0%)",
                      cursor: "pointer",
                    }}
                    onClick={() => dispatch(deleteShipping({ id: item.id }))}
                  >
                    <img src={trash} alt="trash" />
                  </Box>
                </Box>
              );
            })}
          </Box>
        </ProfileCard>
      )}

      <ProfileCard mt="50px">
        {/* <ShippingForm onSubmit={handlePostShipping} checked={false}/> */}
        <AddressSave onSubmit={handlePostShipping} checked={false} />

        <Box sx={{ textAlign: "right" }}>
          <GrayButton
            sx={{
              width: { md: "200px" },
              height: "48px",
              "&:hover": {
                background: "rgba(127, 127, 127, 1)",
              },
              mt: "24px",
              padding: "10px",
            }}
            type="submit"
            onClick={handlePostShipping}
          >
            Save Changes
          </GrayButton>
        </Box>
      </ProfileCard>

      <DialogShipping open={open} handleClose={handleClose} />
    </>
  );
};

export default AddressSection;
