import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { getBestSellers } from "../../store/bestseller/bestsellersSlice";

import { Box, Typography } from "@mui/material";
import { MultipleItems } from "./ProductsSlider";

export default function BestSellerProducts() {
  const dispatch = useDispatch();
  const { loading, error, items } = useSelector((state) => state.bestSellers);

  useEffect(() => {
    dispatch(getBestSellers());
  }, [dispatch]);
  console.log(items);
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Box sx={{ margin: "100px", marginX: "0px" }}>

    <Typography component={'h2'} sx={{
        fontSize:"32px",
        fontWeight:'700',textAlign:"center",
        marginBottom:"52px"
    }}>Best Seller</Typography>
      <MultipleItems items={items} />
    </Box>
  );
}
