import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import HeroTitle from "../../components/layout/heroTitle";
import {
  GrayButton,
  InputControl,

} from "../../shared/style";

import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  getCustomer,
  setValueUser,
  updateCustomer,
} from "../../store/profile/profileSlice";
import PageMeta from "../../components/layout/PageMeta";
import { useEffect } from "react";


import { ProfileCard } from "../../shared/style";

import EditIcon from "../../assets/icons/editIcon.png";

import SideNav from "../../components/profile/sideNav";


const AccountInfo = () => {
  // edit Account
  const [editAcc, setEditAcc] = useState(true);

  // Account Info
  const { profile, customer } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const crumbs = [
    { label: "Home", link: "/", active: false },
    { label: "profile", link: "/profile", active: true },
  ];

  useEffect(() => {
    // dispatch(getProfile());
    dispatch(getCustomer());
  }, []);

  const handleChange = (e) => {
    dispatch(setValueUser(e.target));
  };

  return (
    <>
      <PageMeta title={`Grays and Danny's `} desc="user profile" />
      <Box>
        <Container>
          {/* hero Title */}
          <Box mt="32px">
            <HeroTitle crumbs={crumbs} />
          </Box>
          {/* Account Page */}
          <Box
            sx={{
              background: "rgba(235, 235, 235, 1)",
              borderRadius: "4px",
              padding: "9px 16px",
              mb: "32px",
              mt: "24px",
            }}
          >
            <Typography
              sx={{
                color: "rgba(204, 134, 72, 1)",
                fontSize: "22px",
                fontWeight: "500",
              }}
            >
              Account Profile
            </Typography>
          </Box>

          {/* -----
                Grid container
                -----
            */}
          <Grid container spacing={4} mb="48px">
            {/* col 1 */}
            <Grid item xs={12} sm={4}>
              <SideNav />
            </Grid>
            {/* col 2 */}
            <Grid item xs={12} sm={8}>
              <Box>
                <ProfileCard>
                  {/* info Box */}
                  <Stack direction="row" justifyContent="space-between">
                    <Typography
                      sx={{
                        color: "#212121",
                        fontWeight: "600",
                        fontSize: "20px",
                        mb: "24px",
                      }}
                    >
                      Account Info
                    </Typography>
                    <Box>
                      <img
                        src={EditIcon}
                        style={{ cursor: "pointer" }}
                        onClick={() => setEditAcc((oldValue) => !oldValue)}
                      />
                    </Box>
                  </Stack>

                  {/* form */}
                  <form
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "16px",
                    }}
                    onSubmit={(e) => {
                      e.preventDefault();
                      dispatch(updateCustomer({ ...customer }));
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "10px",
                        flexWrap: { xs: "wrap", md: "nowrap" },
                      }}
                    >
                      <InputControl
                        label=""
                        variant="outlined"
                        placeholder="First Name"
                        type="text"
                        id="firstName"
                        name="first_name"
                        value={customer?.first_name}
                        onChange={handleChange}
                        disabled={editAcc}
                      />
                      <InputControl
                        label=""
                        variant="outlined"
                        placeholder="last Name"
                        type="text"
                        id="lastName"
                        name="last_name"
                        value={customer?.last_name}
                        onChange={handleChange}
                        disabled={editAcc}
                      />
                    </Box>
                    <Box>
                      <InputControl
                        label=""
                        variant="outlined"
                        placeholder="Address"
                        type="text"
                        id="address"
                        name="address_apartment"
                        value={customer?.address_apartment}
                        onChange={handleChange}
                        disabled={editAcc}
                      />
                    </Box>
                    <Box>
                      <InputControl
                        label=""
                        variant="outlined"
                        placeholder="Email Address"
                        type="text"
                        id="email"
                        name="email"
                        value={profile?.email}
                        disabled
                        onChange={handleChange}
                      />
                    </Box>
                    <Box>
                      <InputControl
                        label=""
                        variant="outlined"
                        placeholder="phone"
                        type="text"
                        id="phone"
                        name="phone_number"
                        value={customer.phone_number}
                        onChange={handleChange}
                        disabled={editAcc}
                      />
                    </Box>

                    <Box sx={{ textAlign: "right" }}>
                      <GrayButton
                        sx={{
                          width: { md: "384px" },
                          height: "48px",
                          "&:hover": {
                            background: "rgba(127, 127, 127, 1)",
                          },
                          "&.Mui-disabled": {
                            background: "rgba(127, 127, 127, 50%)",
                            color: "#fff",
                          },
                        }}
                        type="submit"
                        disabled={editAcc}
                      >
                        Save Changes
                      </GrayButton>
                    </Box>
                  </form>
                </ProfileCard>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default AccountInfo;
