import styled from "@emotion/styled";
import { Box, Typography, TextField, Button } from "@mui/material";

// for Main paragraph
export const P = styled(Typography)({
  fontSize: "24px",
  color: "#212121",
  fontFamily: "Poppins, sans-serif",
  fontWeight: "500",
});

/* 
 -------- 
  sign ( login in and Sign up Pages)
 -------- 
*/
// for Card Login and Sign UP
export const CardSign = styled(Box)({
  background: "#fff",
  boxShadow: "0px 19px 40px rgba(0, 0, 0, 0.05)",
  borderRadius: "20px",
  width: "598px",
  padding: "70px 20px",
  "@media (min-width: 600px)": {
    padding: "70px 66px",
  },
});

// InputField Background is #fff
export const InputField = styled(TextField)({
  width: "100%",
  input: {
    background: "#FFF6F4",
    borderRadius: "4px",
    borderBottomColor: "#6E2E02",
    backgroundColor:"#F3EDEC"
  },
  "& label.Mui-focused": {
    color: "transparent",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#6E2E02",
  },
  "& .MuiOutlinedInput-root": {
    // border:"1px solid transparent",
    // borderBottomColor: "#6E2E02",

    "& fieldset": {
      borderColor: "transparent",
    },
    "&:hover fieldset": {
      borderColor: "transparent",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#6E2E02",
    },
  },
});

/* 
 -------- 
  cart - profile 
  input field >> background is White
 -------- 
*/
export const InputControl = styled(TextField)({
  width: "100%",
  input: {
    background: "#fff",
    border: "1px solid #C1C1C1",
    borderRadius: "5px",
  },
  "& label.Mui-focused": {
    color: "transparent",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#6E2E02",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "transparent",
    },
    "&:hover fieldset": {
      borderColor: "transparent",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#6E2E02",
    },
  },
});


/* Card */
export const ProfileCard = styled(Box)({
  background: "#fff",
  boxShadow:
    "-2.5px -2.5px 5px rgba(179, 179, 179, 0.08), 2.5px 2.5px 8px rgba(53, 53, 53, 0.2)",
  borderRadius: "20px",
  padding: "24px 24px",
});

/* 
 -------- 
  Buttons
 -------- 
*/
export const MainButton = styled(Button)({
  background: "linear-gradient(114.83deg, #6E2E02 -19.62%, #CC8648 100%)",
  color: "#fff",
  width: "80%",
  borderRadius: "10px",
  padding: "12px 0",
  textTransform: "capitalize",
  fontSize: "16px",
  fontWeight: "400",
  letterSpacing: "-0.24px",
});

export const GrayButton = styled(Button)({
  background: "rgba(127, 127, 127, 1)",
  color: "#fff",
  width: { md: "384px" },
  height: "48px",
  border: "none",
  fontSize: "16px",
  fontWeight: "400",
  padding: "0 20px",
  borderRadius: "10px",
  cursor: "pointer",
  textTransform: "capitalize",

});


export const Span = styled(Typography)({
  background: "#F5F5F5",
  borderRadius: "6px",
  color: "#000",
  display: "inline-block",
  padding: "0px",
  width: "32px",
  height: "32px",
  textAlign: "center",
  cursor: "pointer",
  lineHeight: "32px",
  "&:hover": {
    background: "#CC8648",
    color:"#fff"
  },
});
