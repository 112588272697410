import {
    Box,
    Button,
    Container,
    Grid,
    InputLabel,
    Stack,
    Typography,
  } from "@mui/material";
  import HeroTitle from "../../components/layout/heroTitle";

  import { useState } from "react";
  import { useNavigate } from "react-router-dom";
  import { useFormik } from "formik";
  import { useDispatch, useSelector } from "react-redux";
  import {

    postChangePassword,
    setValueUser,
  } from "../../store/profile/profileSlice";
  import * as Yup from "yup";
  import PageMeta from "../../components/layout/PageMeta";
  import { setUser } from "../../store/user/userSlice";
  import { useEffect } from "react";
  import {
    getShipping,
  } from "../../store/shipping/shippingSlice";
  

  import AddressSection from "../../components/profile/addressSection";

import SideNav from "../../components/profile/sideNav";

  
  const Address = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const crumbs = [
      { label: "Home", link: "/", active: false },
      { label: "profile", link: "/profile", active: true },
    ];
  
    useEffect(() => {
      dispatch(getShipping());
    }, []);
  
    return (
      <>
        <PageMeta title={`Grays and Danny's `} desc="user profile" />
        <Box>
          <Container>
            {/* hero Title */}
            <Box mt="32px">
              <HeroTitle crumbs={crumbs} />
            </Box>
            {/* Account Page */}
            <Box
              sx={{
                background: "rgba(235, 235, 235, 1)",
                borderRadius: "4px",
                padding: "9px 16px",
                mb: "32px",
                mt: "24px",
              }}
            >
              <Typography
                sx={{
                  color: "rgba(204, 134, 72, 1)",
                  fontSize: "22px",
                  fontWeight: "500",
                }}
              >
                Account Profile
              </Typography>
            </Box>
  
            {/* -----
                Grid container
                -----
            */}
            <Grid container spacing={4} mb="48px">
              {/* col 1 */}
              <Grid item xs={12} sm={4}>
                <SideNav />
              </Grid>
              {/* col 2 */}
              <Grid item xs={12} sm={8}>
                <Box>
                  <AddressSection />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </>
    );
  };
  
  export default Address;