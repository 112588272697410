import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
  } from "@mui/material";
  import React from "react";
  import { InputControl } from "../../shared/style";
  import RadioCheck from "../common/radioCheck";
  import { useDispatch, useSelector } from "react-redux";
  import {
    clearForm,
    setChecked,
    setCheckedSavedAdress,
    setRetail,
    updateForm,
  } from "../../store/shipping/shippingSlice";
  import { countries } from "../../data/country";
  
  const AddressSave = ({onSubmit, checked}) => {
    const {
      country: countryValue,
      name,
      company_name,
      house_number,
      city,
      postal_code,
      governoate,
      phone,
      address,
      phone_number
    } = useSelector((state) => state?.shipping?.shipping);
  
    const { errorMsg, allShipping, checkedSavedAdress, retail } =
      useSelector((state) => state.shipping);
  
    const { items } = useSelector((state) => state.cart);
  
    const token = localStorage.getItem("token");
    const dispatch = useDispatch();
  
    // Change Forms inputs And update Form
    const handleChange = (e) => {
      const { name, value } = e.target;
      dispatch(updateForm({ name, value }));
    };
    
    return (
      <>
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "14px",
          }}
          onSubmit={onSubmit}
        >
          <Typography
              variant="h4"
              component="h3"
              sx={{
                fontSize: "20px",
                fontWeight: "600",
                marginLeft: "8px",
                mb: "10px",
              }}
            >
              Address
            </Typography>
          <Box>
            <FormControl fullWidth>
              <InputLabel id="country">country</InputLabel>
              <Select
                labelId="country"
                label="country"
                id="country"
                name="country"
                disabled={checked}
                value={countryValue}
                onChange={handleChange}
                sx={{
                  ".Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#6E2E02",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #C1C1C1",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#6E2E02",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#6E2E02",
                  },
                }}
              >
                <MenuItem value={"US"}>USA</MenuItem>
                {countries?.map((ele, index) => {
                  return (
                    <MenuItem value={Object.keys(ele)[0]} key={index}>
                      {ele[Object.keys(ele)].country}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
  
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
              flexWrap: { xs: "wrap", md: "nowrap" },
            }}
          >
            <Box sx={{ flex: 1 }}>
              <InputControl
                label=""
                variant="outlined"
                placeholder="First Name"
                type="text"
                id="firstName"
                name="name"
                disabled={checked}
                value={name}
                onChange={handleChange}
              />
              {errorMsg?.name && (
                <span
                  style={{
                    color: "#E81717",
                    fontSize: "13px",
                    paddingLeft: "5px",
                  }}
                >
                  this field is required
                </span>
              )}
            </Box>
            <Box sx={{ flex: 1 }}>
              <InputControl
                label=""
                variant="outlined"
                placeholder="Last Name"
                id="lastName"
                name="company_name"
                value={company_name}
                onChange={handleChange}
                disabled={checked}
              />
            </Box>
          </Box>
          <Box>
            <InputControl
              variant="outlined"
              label=""
              placeholder="Address"
              id="address"
              name="address"
              value={address}
              onChange={handleChange}
              disabled={checked}
            />
            {errorMsg?.address && (
              <span
                style={{
                  color: "#E81717",
                  fontSize: "13px",
                  paddingLeft: "5px",
                }}
              >
                this field is required
              </span>
            )}
          </Box>
          <Box>
            <InputControl
              variant="outlined"
              label=""
              placeholder="phone number"
              id="phone_number"
              name="phone_number"
              value={phone_number}
              onChange={handleChange}
              disabled={checked}
            />
            {errorMsg?.phone_number && (
              <span
                style={{
                  color: "#E81717",
                  fontSize: "13px",
                  paddingLeft: "5px",
                }}
              >
                this field is required
              </span>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
              flexWrap: { xs: "wrap", md: "nowrap" },
            }}
          >
            <Box sx={{ flex: 1 }}>
              <InputControl
                variant="outlined"
                label=""
                placeholder="city"
                id="city"
                name="city"
                value={city}
                onChange={handleChange}
                disabled={checked}
              />
              {errorMsg?.city && (
                <span
                  style={{
                    color: "#E81717",
                    fontSize: "13px",
                    paddingLeft: "5px",
                  }}
                >
                  this field is required
                </span>
              )}
            </Box>
            <Box sx={{ flex: 1 }}>
              <InputControl
                variant="outlined"
                label=""
                placeholder="Postal code"
                id="postal_code"
                name="postal_code"
                value={postal_code}
                onChange={handleChange}
                disabled={checked}
                type="number"
              />
              {errorMsg?.postal_code && (
                <span
                  style={{
                    color: "#E81717",
                    fontSize: "13px",
                    paddingLeft: "5px",
                  }}
                >
                  this field is required
                </span>
              )}
            </Box>
          </Box>
  
          <Button type="submit" sx={{ display: "none" }}></Button>
        </form>
      </>
    );
  };
  
  export default AddressSave;