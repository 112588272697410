import styled from "@emotion/styled";
import {
  Box,
  Container,
  Grid,
  TextField,
  Typography,
  Stack,
  Button,
} from "@mui/material";

import icons from "../../assets/home/vector1.svg";
import icons2 from "../../assets/home/vector2.svg";
import icons3 from "../../assets/home/vector3.svg";
import appStore from "../../assets/home/appStore.png";
import googlePlay from "../../assets/home/googlePlay.png";
import send from "../../assets/home/send.svg";
import cypartaLogo from "../../assets/icons/cypartaLogo.png";
import { Link } from "react-router-dom";

import { HashLink } from "react-router-hash-link";

import { PLAYSTORE } from "../../data/API";
const NavbarNav = styled("ul")({
  listStyle: "none",
  padding: "0",
});
const NavItem = styled("li")({
  color: "#fff",
  marginBottom: "8px",
  fontWeight: "400",
  fontSize: "14px",
});
const Footer = () => {
  return (
    <Box
      sx={{
        background: "#6E2E02",
        color: "#FFFFFF",
      }}
    >
      <Container>
        <Grid container spacing={4} sx={{ paddingTop: "48px" }}>
          <Grid item xs={12} sm={6} md={4}>
            <Box>
              <Typography
                variant="h4"
                component="h4"
                mb="8px"
                sx={{ fontWeight: 700, fontSize: "20px" }}
              >
                Home
              </Typography>
              <Box
                sx={{
                  width: "80%",
                  height: "1px",
                  background: "#C9C9C9",
                  mb: "8px",
                }}
              ></Box>
              <NavbarNav>
                <NavItem>
                  <Link to="/retail">retail</Link>
                </NavItem>
                <NavItem>
                  <Link to="/wholesale">Wholesale</Link>
                </NavItem>
                <NavItem>
                  <Link to="/odhiah">Odhiah</Link>
                </NavItem>
                <NavItem>
                  <Link to="/aqqeqa">Aqqeqa</Link>
                </NavItem>
              </NavbarNav>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box>
              <Typography
                variant="h4"
                component="h4"
                mb="8px"
                sx={{ fontWeight: 700, fontSize: "20px" }}
              >
                About us
              </Typography>
              <Box
                sx={{
                  width: "80%",
                  height: "1px",
                  background: "#C9C9C9",
                  mb: "8px",
                }}
              ></Box>
              <NavbarNav>
                <NavItem>
                  <HashLink
                    smooth
                    to={`/#services`}
                    style={{  }}
                  >
                    Services
                  </HashLink>
                </NavItem>
                <NavItem>
                  <HashLink
                    smooth
                    to={`/#contact`}
                    style={{  }}
                  >
                    Contact Us
                  </HashLink>
                </NavItem>
                <NavItem>
                  <HashLink
                    smooth
                    to={`/#contact`}
                    style={{  }}
                  >
                    Branches
                  </HashLink>
                </NavItem>
                <NavItem>
                  <Link to="/retail">Search</Link>
                </NavItem>
              </NavbarNav>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box>
              <Typography
                variant="h4"
                component="h4"
                mb="8px"
                sx={{ fontWeight: 700, fontSize: "20px" }}
              >
                Contact With Us
              </Typography>
              <Box
                sx={{
                  width: "80%",
                  height: "1px",
                  background: "#C9C9C9",
                  mb: "8px",
                }}
              ></Box>
              <Typography variant="p" component="p" mb="16px">
                Enter Your mail to Contact you
              </Typography>
              <Box sx={{ display: "flex" }}>
                <TextField
                  id="outlined-basic"
                  label=""
                  placeholder="Email Address"
                  variant="outlined"
                  sx={{
                    input: {
                      background: "#fff",
                      borderRadius: "10px 0px 0 10px",
                    },
                  }}
                />
                <Button
                  sx={{
                    background: "#CC8648",
                    marginLeft: "-3px",
                    borderRadius: "0 10px 10px 0",
                  }}
                >
                  <img src={send} alt="send" />
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box
          sx={{
            width: "100%",
            height: "1px",
            background: "#C9C9C9",
            mb: "8px",
            mt: "32px",
            display: { xs: "none", md: "flex" },
          }}
        ></Box>
      </Container>
      <Container>
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent={{ xs: "flex-start", md: "space-between" }}
          alignItems={{ xs: "flex-start", md: "center" }}
          mt="32px"
          pb="80px"
        >
          <Box
            sx={{
              flex: "1",
              display: { xs: "none", md: "flex" },
            }}
          >
            <img
              src={icons}
              alt="link to grays and danny's Facebook"
              width="24px"
              height="24px"
            />
            <img
              src={icons2}
              alt="link to grays and danny's whatsApp"
              style={{ marginLeft: "15px" }}
              width="24px"
              height="24px"
            />
            <img
              src={icons3}
              alt="test"
              style={{ marginLeft: "15px" }}
              width="24px"
              height="24px"
            />
          </Box>
          <Box
            sx={{
              flex: 1,
              display: { xs: "none", md: "flex" },
              alignItems: "center",
            }}
          >
            <Box>Powered by : </Box>{" "}
            <Box>
              {" "}
              <a href="https://cyparta.com/" target="_blank">
                <img
                  src={cypartaLogo}
                  alt="cyparta logo /-grays and danny's "
                />
              </a>
            </Box>
          </Box>
          <Box sx={{ flex: 1 }}>
            <a href={PLAYSTORE} target="_blank">
              <Button>
                <img
                  src={googlePlay}
                  alt="Download grays and danny's from google play"
                  width={"100%"}
                  height={"100%"}
                />
              </Button>
            </a>
            <Button>
              <img
                src={appStore}
                alt="Download grays and danny's from app store"
                width="100%"
                height="100%"
              />
            </Button>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};

export default Footer;
