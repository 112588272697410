import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { useDispatch, useSelector } from "react-redux";
import { getMeat, setRange } from "../../store/shop/shopSlice";

function valuetext(value) {
  return `${value}°C`;
}

export default function RangeSlider() {
  const dispatch = useDispatch();
  const rangValue = useSelector((state) => state.shop.rangeValue);
  return (
    <Box sx={{ height: "10px", mb: "10px"}}>
      <Slider
        getAriaLabel={() => "price range"}
        value={rangValue}
        onChange={(e, newValue) => {
          dispatch(setRange(newValue));
          dispatch(getMeat());
        }}
        max={100000}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        color="test3"
      />
    </Box>
  );
}
