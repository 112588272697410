import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import HeroTitle from "../../components/layout/heroTitle";
import { InputControl } from "../../shared/style";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { postChangePassword } from "../../store/profile/profileSlice";
import * as Yup from "yup";
import PageMeta from "../../components/layout/PageMeta";
import { setUser } from "../../store/user/userSlice";

import { ProfileCard } from "../../shared/style";
import SideNav from "../../components/profile/sideNav";


const ChangePassword = () => {
  // inputs for password
  const [showCurrent, setShowCurrent] = useState(true);
  const [showNewPass, setShowNewPass] = useState(true);
  const [showConfirmPass, setShowConfirmPass] = useState(true);

  // Account Info
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const crumbs = [
    { label: "Home", link: "/", active: false },
    { label: "profile", link: "/profile", active: true },
  ];

  const logOut = () => {
    localStorage.removeItem("token");
    dispatch(setUser(null));
    navigate("/login");
  };

  const formik = useFormik({
    initialValues: {
      new_password: "",
      current_password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object().shape({
      confirm_password: Yup.string().oneOf(
        [Yup.ref("new_password"), null],
        "Passwords must match"
      ),
    }),
    onSubmit: (values, { resetForm }) => {
      const { new_password, current_password } = values;
      dispatch(postChangePassword({ new_password, current_password }));
      resetForm({ values: "" });
    },
  });

  return (
    <>
      <PageMeta title={`Grays and Danny's `} desc="user profile" />
      <Box>
        <Container>
          {/* hero Title */}
          <Box mt="32px">
            <HeroTitle crumbs={crumbs} />
          </Box>
          {/* Account Page */}
          <Box
            sx={{
              background: "rgba(235, 235, 235, 1)",
              borderRadius: "4px",
              padding: "9px 16px",
              mb: "32px",
              mt: "24px",
            }}
          >
            <Typography
              sx={{
                color: "rgba(204, 134, 72, 1)",
                fontSize: "22px",
                fontWeight: "500",
              }}
            >
              Account Profile
            </Typography>
          </Box>

          {/* -----
                Grid container
                -----
            */}
          <Grid container spacing={4} mb="48px">
            {/* col 1 */}
            <Grid item xs={12} sm={4}>
              <SideNav />
            </Grid>
            {/* col 2 */}
            <Grid item xs={12} sm={8}>
              <Box>
                <ProfileCard>
                  <form onSubmit={formik.handleSubmit}>
                    <Box>
                      <Typography
                        sx={{
                          color: "#212121",
                          fontSize: "20px",
                          fontWeight: "500",
                        }}
                      >
                        Change Password
                      </Typography>
                      <Box mb="20px" mt="24px">
                        <Box sx={{ position: "relative" }}>
                          <InputControl
                            label=""
                            variant="outlined"
                            id="current_password"
                            name="current_password"
                            type={showCurrent ? "password" : "text"}
                            value={formik.values.current_password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="old password"
                          />
                          <VisibilityOffIcon
                            sx={{
                              position: "absolute",
                              top: "50%",
                              right: "20px",
                              transform: "translateY(-50%)",
                              fontSize: "15px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              setShowCurrent((oldValue) => !oldValue)
                            }
                          />
                        </Box>
                      </Box>
                      <Box mb="20px" mt="24px">
                        <Box sx={{ position: "relative" }}>
                          <InputControl
                            label=""
                            variant="outlined"
                            type={showNewPass ? "password" : "text"}
                            id="new_password"
                            name="new_password"
                            value={formik.values.new_password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="new password"
                          />
                          <VisibilityOffIcon
                            sx={{
                              position: "absolute",
                              top: "50%",
                              right: "20px",
                              transform: "translateY(-50%)",
                              fontSize: "15px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              setShowNewPass((oldValue) => !oldValue)
                            }
                          />
                        </Box>
                      </Box>
                      <Box mb="20px" mt="24px">
                        <Box sx={{ position: "relative" }}>
                          <InputControl
                            label=""
                            variant="outlined"
                            id="confirm_password"
                            name="confirm_password"
                            type={showConfirmPass ? "password" : "text"}
                            value={formik.values.confirm_password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="confirm password"
                          />
                          <VisibilityOffIcon
                            sx={{
                              position: "absolute",
                              top: "50%",
                              right: "20px",
                              transform: "translateY(-50%)",
                              fontSize: "15px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              setShowConfirmPass((oldValue) => !oldValue)
                            }
                          />
                        </Box>
                        {formik.touched.confirm_password &&
                        formik.errors.confirm_password ? (
                          <Typography
                            component="span"
                            sx={{ color: "red", mt: "1px", fontSize: "14px" }}
                          >
                            {formik.errors.confirm_password}
                          </Typography>
                        ) : null}
                      </Box>
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Button
                          type="submit"
                          sx={{
                            background:
                              "linear-gradient(114.83deg, #6E2E02 -19.62%, #CC8648 100%)",
                            color: "#fff",
                            width: "80%",
                            borderRadius: "10px",
                            padding: "12px 0",
                            textTransform: "capitalize",
                            fontSize: "16px",
                            fontWeight: "400",
                            letterSpacing: "-0.24px",
                          }}
                        >
                          Update Password
                        </Button>
                      </Box>
                    </Box>
                  </form>
                </ProfileCard>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default ChangePassword;
