import { Box } from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getCustomer,
  getProfile,
  setValueUser,
} from "../../store/profile/profileSlice";
import { setUser } from "../../store/user/userSlice";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { clearItems } from "../../store/cart/cartSlice";

const SideNav = () => {
  // Account Info
  const [activeCard, setActiveCard] = useState(1);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  console.log(location.pathname)

  const logOut = () => {
    localStorage.removeItem("token");
    dispatch(setUser(null));
    dispatch(clearItems());
    navigate("/login");
  };

  const sideProfileNav = [
    { title: "Account info", to: "/profile" },
    { title: "addresses", to: "/profile/address" },
    { title: "password", to: "/profile/change-password" },
    { title: "order", to: "/profile/order" },
  ];
  return (
    <Box mb="10px">
      {sideProfileNav.map((item, index) => {
        return (
          <Box>
            <Link to={item.to}>
              <button
                className={
                    location.pathname === item.to
                    ? "main-btn-outline"
                    : "main-btn-outline main-btn-not-active"
                }
                style={{
                  marginBottom: "20px",
                  cursor: "pointer",
                  borderRadius: "10px",
                  textAlign: "left",
                }}
              >
                {item.title}
              </button>
            </Link>
          </Box>
        );
      })}
      <Box>
        <button
          className={
            activeCard === 3
              ? "main-btn-outline"
              : "main-btn-outline main-btn-not-active"
          }
          style={{
            marginBottom: "20px",
            cursor: "pointer",
            borderRadius: "10px",
            border: "none",
            color: "#000 !important",
            textAlign: "left",
          }}
          onClick={logOut}
        >
          Log Out
        </button>
      </Box>
    </Box>
  );
};

export default SideNav;
