import {
  Box,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import HeroTitle from "../../components/layout/heroTitle";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import PageMeta from "../../components/layout/PageMeta";
import { setUser } from "../../store/user/userSlice";
import { useEffect } from "react";
import { ProfileCard } from "../../shared/style";

import { getOrder } from "../../store/order/order";
import moment from "moment";
import { Link } from "react-router-dom";
import SideNav from "../../components/profile/sideNav";


const Order = () => {
  // Account Info
  const [activeCard, setActiveCard] = useState(1);
  const { orders, order: orderID } = useSelector((state) => state.order);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const crumbs = [
    { label: "Home", link: "/", active: false },
    { label: "profile", link: "/profile", active: true },
  ];

  useEffect(() => {
    dispatch(getOrder());
  }, []);

  return (
    <>
      <PageMeta title={`Grays and Danny's `} desc="user profile" />
      <Box>
        <Container>
          {/* hero Title */}
          <Box mt="32px">
            <HeroTitle crumbs={crumbs} />
          </Box>
          {/* Account Page */}
          <Box
            sx={{
              background: "rgba(235, 235, 235, 1)",
              borderRadius: "4px",
              padding: "9px 16px",
              mb: "32px",
              mt: "24px",
            }}
          >
            <Typography
              sx={{
                color: "rgba(204, 134, 72, 1)",
                fontSize: "22px",
                fontWeight: "500",
              }}
            >
              Account Profile
            </Typography>
          </Box>

          {/* -----
                Grid container
                -----
            */}
          <Grid container spacing={4} mb="48px">
            {/* col 1 */}
            <Grid item xs={12} sm={4}>
              <SideNav />
            </Grid>
            {/* col 2 */}
            <Grid item xs={12} sm={8}>
              <Box>
                <>
                  {orders?.map((order) => (
                    <ProfileCard mb="24px">
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ flex: "1" }}>
                          <Typography
                            sx={{
                              color: "#212121",
                              fontWeight: "600",
                              fontSize: "20px",
                              mb: "24px",
                            }}
                          >
                            {moment(order?.placed_at).format("dddd")}{" "}
                            {moment(order?.placed_at).format("hh:mm A")}
                            {/* {new Date(order?.placed_at).toLocaleDateString("en-US")} */}
                          </Typography>
                          <Box sx={{ display: "flex", gap: "10px" }}>
                            <Typography
                              sx={{
                                color: "rgba(127, 127, 127, 1)",
                                fontSize: "15px",
                                fontWeight: "500",
                                mb: "11px",
                                flex: "100px",
                                maxWidth: "100px",
                              }}
                            >
                              Order date
                            </Typography>
                            <Typography
                              sx={{
                                color: "rgba(127, 127, 127, 1)",
                                fontSize: "15px",
                                fontWeight: "500",
                                mb: "11px",
                              }}
                            >
                              {new Date(order?.placed_at).toLocaleDateString(
                                "en-US"
                              )}
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", gap: "10px" }}>
                            <Typography
                              sx={{
                                color: "rgba(127, 127, 127, 1)",
                                fontSize: "15px",
                                fontWeight: "500",
                                mb: "11px",
                                flex: "100px",
                                maxWidth: "100px",
                              }}
                            >
                              Order Total
                            </Typography>
                            <Typography
                              sx={{
                                color: "rgba(127, 127, 127, 1)",
                                fontSize: "15px",
                                fontWeight: "500",
                                mb: "11px",
                              }}
                            >
                              {order.total_price} $ ({order.items.length} item)
                            </Typography>
                          </Box>
                        </Box>
                        <Box sx={{ alignItems: "flex-end", display: "flex" }}>
                          <Link to={`/profile/order/${order.id}`}>
                            <ChevronRightIcon
                              sx={{ fontSize: "32px", cursor: "pointer" }}
                            />
                          </Link>
                        </Box>
                      </Box>
                    </ProfileCard>
                  ))}
                </>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Order;
