import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";
import { BASEURL } from "../../data/API";
// import bestSellersSlice from './bestsellersSlice';
export const getBestSellers = createAsyncThunk("getBestSellers", async () => {
  const response = await axios.get(`${BASEURL}bestsellers/`);
  return response.data;
});

const initialState = {
  loading: false,
  error: null,
  items: [],
};

export const bestSellersSlice = createSlice({
  name: "bestSellers",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBestSellers.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.items = [];
      })
      .addCase(getBestSellers.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
      })
      .addCase(getBestSellers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});



export default bestSellersSlice.reducer;